<template>
  <div class="contacts container">
    <h1>Контакты</h1>

    <p>Организуем доставку оборудованиея, для производства органических удобрений и грунтосмесей, завода Проммаш в любую точку мира. Наш отдел логистики поможет Вам выбрать оптимальный способ и грамотно организовать процесс транспортировки оборудования: автомобильным, железнодорожным либо морским транспортом.</p>

    <h4>Карта</h4>

    <h4>Телефоны</h4>
    <p>+38(050)422-74-92</p>
    <p>+38(095)135-20-13</p>

    <h4>Почтовый ящик</h4>
    <p>azpm2006@gmail.com</p>

    <h4>Форма отправки писем</h4>






    <h4> Адрес</h4>

    <p>Страна Украина</p>
    <p>Область Донецкая</p>
    <p>Индекс 84500</p>
    <p>Город Бахмут (рание г. Артемовск)</p>
    <p>Адрес Героев Труда, 15 (рание ул. Кирова) </p>
    <p>GPS N46.7745 S65.789</p>
  </div>
</template>
